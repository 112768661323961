import React, { useEffect, useContext, useRef } from 'react';
import GsapContext from '../context/GsapContext';
import SplitText from 'gsap/SplitText';

const Tween = React.forwardRef((
  {
    from,
    to,
    stagger,
    children,
    splitText,
  },
  ref
) => {
  const gsap = useContext(GsapContext);
  const tweenRef = useRef();

  useEffect(() => {
    if (gsap) {
      const currentTarget = getCurrentTarget();

      if (from && to) {
        gsap.fromTo(currentTarget, from, to);
      } else {
        if (from) {
          gsap.from(currentTarget, {
            ...from,
            stagger,
          });
        }
    
        if (to) {
          gsap.to(currentTarget, {
            ...to,
            stagger,
          });
        }
      }
    }
  }, [gsap]);

  const getCurrentTarget = () => {
    let target = tweenRef.current;

    if (splitText) {
      const split = new SplitText(target, {position: "absolute"});

      if (splitText === 'chars') {
        // console.log('ici', splitText)
        target = split.chars;
      } else if (splitText === 'lines') {
        target = split.lines;
      } else if (splitText === 'words') {
        target = split.words;
      }
    }

    // console.log({target})

    return target;
  }

  return (
    <div ref={ref}>
      {React.Children.only(
        React.cloneElement(children, {
          ref: (ref) => (tweenRef.current = ref)
        }
      ))}
    </div>
  )
});

export default Tween;