import React from 'react'
import styled from 'styled-components'

const TitleHead = styled.h1`
    font-size: 233px;
    font-family: 'Playfair Display', sans-serif !important;
    font-style: italic;
    font-weight: 700;
    margin-top: -95px;
    text-indent: -0.5em;
    padding-left: 0.5em;

    @media (max-width: 768px) {
      margin-top: 0;
      font-size: 150px;
  }

    @media (max-width: 512px) {
        margin-top: 0;
        font-size: 85px;
        text-indent: inherit;
        padding-left: 0;
    }
`;

const Title = React.forwardRef(({ children, ...other }, ref) => (
  <TitleHead ref={ref} className="header-title" {...other}>
    {children}
  </TitleHead>
));

export default Title;
