import React from 'react'
import useAxios from 'axios-hooks';
import { Row, Col, Container } from 'react-bootstrap';
import Extract from '../utils/Extract';
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Skeleton from 'react-loading-skeleton';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';

const Title = styled.h2`
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
`

const BookTitle = styled.p`
    margin-bottom: 0;
    font-size: 38px;
    font-family: 'Playfair Display', sans-serif !important;
    font-style: italic;
`

const Author = styled.p`
    margin-bottom: 0;
    font-size: 40px;
    font-family: 'Catamaran', sans-serif !important;
    font-weight: 500;
`

const Edition = styled.p`
    font-size: 22px;
    font-family: 'Catamaran', sans-serif !important;
    font-weight: 500;
`

const Description = styled(motion.p)`
    font-size: 22px;
    font-family: 'Playfair Display', sans-serif !important;
    font-style: italic;
`

const DownloadButton = styled.p`
    font-size: 26px;
    margin-top: 30px;
`

const Download = styled.a`
    font-size: 26px;
    margin-top: 30px;

    &:hover { color: #fff; }
`

const Laureate = React.forwardRef((props, ref) => {
    const [{ data: booksData, loading }] = useAxios('/ppda/books');
    const book = booksData?.data?.[0]?.novels?.[0]

    return (
        <Tween from={{ opacity: 0 }} to={{ opacity: 1, }}>
            <section ref={ref} id="section-laureate" className="section-laureate" {...props}>
                {book &&
                    <Container>
                        <Row className="mb-4">
                            <Col md={{ size: 6, offset: 6 }}>
                                <Controller>
                                    <Scene duration={150} offset={-250} duration={300}>
                                        <Tween from={{ opacity: 0, x: -400 }} to={{ opacity: 1, x: 0 }} duration={3}>
                                            <Title>Téléchargez la<br/> nouvelle Lauréate</Title>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={1} md={6} className="order-2 order-md-1">
                                {/* slogan */}
                                <Extract 
                                    text={book?.hook}
                                    cover={book?.coverName}
                                />
                            </Col>
                            <Col sm={1} md={6} className="order-1 order-md-2">
                                <Controller>
                                    <Scene duration={150} offset={-250} duration={300} >
                                        <Tween from={{ opacity: 0, x: 400 }} to={{ opacity: 1, x: 0 }}>
                                            <BookTitle className="laureat-title">{book?.title},</BookTitle>
                                        </Tween>
                                    </Scene>
                                </Controller>

                                <Controller>
                                    <Scene duration={150} offset={-250} duration={300} >
                                        <Tween from={{ opacity: 0, x: 450 }} to={{ opacity: 1, x: 0 }} >
                                            <Author className="laureat-author">de {book?.author}</Author>
                                        </Tween>
                                    </Scene>
                                </Controller>

                                <Controller>
                                    <Scene duration={150} offset={-250} duration={300} >
                                        <Tween from={{ opacity: 0 }} to={{ opacity: 1 }}>
                                            <Edition>Lauréat édition {book?.edition}</Edition>
                                        </Tween>
                                    </Scene>
                                </Controller>

                                <Controller>
                                    <Scene duration={150} offset={-250} duration={300} >
                                        <Tween from={{ opacity: 0, y: 200 }} to={{ opacity: 1, y: 0 }} >
                                            <Description className="laureat-description">{book?.description}</Description>
                                            <DownloadButton>Téléchargez la nouvelle en cliquant <Download href={"https://hub.agencelafusee.com/uploads/plume/novel/file/" + book?.fileName} target="_blank" rel='noreferrer noopener'>ici</Download></DownloadButton>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </Col>
                        </Row>
                    </Container>
                }
            </section>
        </Tween>
    )
});

export default Laureate
