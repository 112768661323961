import { Field, useField, useFormikContext } from 'formik';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { Overlay, Popover, PopoverContent, Tooltip } from 'react-bootstrap';

const InputFormStyled = styled.span`
    font-size: 23px;
    font-family: 'Catamaran', sans-serif !important;
    font-weight: 500;
    line-height: 28px;
    background: none;
    border: none;
    border-bottom: 1px solid #fff!important;
    border-radius: 0;
    white-space: nowrap;
    padding: 0px;
    opacity: 0.7;
    height: 24px;
    display: inline-block;
    margin-right: 8px;

    ::placeholder {
      color: #fff;
    }

    &:empty {
      &:before {
        content: attr(data-placeholder);
      }
    }

    &:focus {
      background-color: transparent;
      color: #fff;
      opacity: 0.7;
      outline: 0px solid transparent;
  }
`;

const CustomPopoverContent = styled(PopoverContent)`
  font-family: 'Catamaran',sans-serif !important;
  color: #ff0000bf;
  line-height: 28px;
  font-size: 16px;
  font-weight: 500;
`;

const FormControl = ({
  name,
  type,
  placeholder,
  onBlur,
  onFocus,
  ...other
}) => {
  const [eventMeta, setEventMeta] = useState({
    used: false,
    touching: false,
  });
  const [field, meta] = useField({ name });
  const inputRef = useRef();
  const contentRef = useRef();
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (field.value === '') {
      contentRef.current.textContent = field.value;
    }
  }, [field.value])

  const showError = () => {
    return Boolean(
      meta.error && eventMeta.used && !eventMeta.touching,
    );
  };

  const toggleTouching = () => {
    setEventMeta({
      used: eventMeta.used,
      touching: !eventMeta.touching,
    });
  };

  const handleFocus = () => {
    toggleTouching();
  };

  const handleBlur = () => {
    toggleTouching();
  };

  const handleInput = (event, field) => {    
    const { used, touching } = eventMeta;

    if (!used) {
      setEventMeta({
        used: true,
        touching,
      });
    }

    inputRef.current.value = event.currentTarget.textContent;
    setFieldValue(field.name, event.target.innerText);
  }

  return (
    <Field name={name}>
      {({ field }) => (
        <>
          <InputFormStyled
            ref={contentRef}
            onInput={(event) => {
              handleInput(event, field)
            }}
            onBlur={handleBlur}
            onFocus={handleFocus}
            data-placeholder={placeholder}
            contentEditable={true}
            defaultValue={field.value}
          />
          <input
            ref={inputRef}
            name={name}
            type='hidden'
            {...other}
            {...field}
          />
          <Overlay target={contentRef.current} show={showError()} placement="top">
            {(props) => (
              <Popover {...props}>
                <CustomPopoverContent>
                  {meta.error}
                </CustomPopoverContent>
              </Popover>
            )}
        </Overlay>
        </>
      )}
    </Field>
  );
}
    
export default FormControl;