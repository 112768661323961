import React from 'react'
import styled from 'styled-components'
import Rocket from '../../assets/img/Pictos-Univers-Rocket.svg'
import Pen from '../../assets/img/Pictos-Univers-Fountain-pen.svg'
import Camera from '../../assets/img/Pictos-Univers-Camera.svg'
import { motion } from 'framer-motion'

const Container = styled.div`
    background: #003cff;
    position: fixed;
    bottom: 0;
    margin: 20px;
    width: 150px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    z-index: 2;
    border-radius: 8px;
`

const Img = styled.img`
    width: auto;
    height: 35px;
`

const Icons = () => {
    return (
        <Container>
            <motion.a 
                whileHover={{
                    rotate: [-3, 3],
                    transition: {
                        duration: 0.3,
                        repeat: Infinity,
                      }
                }}
                href='https://agencelafusee.com/' target='_blank' rel='noreferrer noopener'><Img src={Rocket} />
            </motion.a>
            <Img src={Pen} />
            <motion.a 
                whileHover={{
                    rotate: [-3, 3],
                    transition: {
                        duration: 0.3,
                        repeat: Infinity,
                      }
                }} 
                href='https://evenement-digital.fr/' target='_blank' rel='noreferrer noopener'><Img src={Camera} />
            </motion.a>
        </Container>
    )
}

export default Icons
