import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap';

const Text = styled.p`
    font-size: 24px;
    font-family: 'Catamaran', sans-serif !important;
    font-weight: 500;
    line-height: 28px;
    text-align: ${props => props.align === 'center' ? "center" : "left" };

    @media (max-width: 768px) {
        margin-top: ${props => props.align === "left" ? "100px" : "initial" };
    }
`

const Button = styled.a`
    background: #fff;
    color: #003cff!important;
    border: 1px solid #fff;
    text-align: center;
    padding: 10px 50px;
    font-size: 20px;
    text-align: center;
    
    &:hover {
        text-decoration: none;
        background: none;
        color: #fff!important;
    }
`

const Order = ({ text, textButton, align, link }) => {
    return (
        <Row>
            <Col>
                {text &&
                    <Text align={align} dangerouslySetInnerHTML={{ __html: text }} className="mb-5" />
                }
                <div className="text-center" style={{height: 44}}>
                    <Button href={link} target='_blank' rel='noreferrer noopener'>{textButton}</Button>
                </div>
            </Col>
        </Row>
    )
}

export default Order
