import React from 'react'
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components'
import Skeleton from 'react-loading-skeleton';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import TimelineText from '../animation/Timeline'
import TweenText from '../animation/Tween'

const RowResponsive = styled(Row)`
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        align-items: start;
    }
`

const LeftIntro = styled.p`  
    font-size: 24px;
    font-family: 'Catamaran', sans-serif !important;
    line-height: 28px;
    padding-right: 34px;
    font-weight: 500;
`

const RightIntro = styled.p`
    font-size: 65px;
    line-height: 70px;

    div {
      font-family: 'Playfair Display', sans-serif !important;
      font-style: italic;
      font-weight: 700;
    }

    @media (max-width: 512px) {
        font-size: 35px;
        line-height: 74px;
    }

    @media (max-width: 375px) {
        font-size: 30px;
        line-height: 74px;
    }
`

const Intro = React.forwardRef(({ textLeft, textRight, loading }, ref) => (

    <RowResponsive ref={ref}>
        <Col sm={1} md={6} className="order-2 order-md-1">
            <TimelineText scrollTrigger={{ start: "top bottom", toggleActions: "restart none restart none"}}>
                <TweenText from={{ opacity: 0, x: "-400" }} to={{ opacity: 1, x: 0, duration: 1.5 }}>
                    <LeftIntro id="left-intro" dangerouslySetInnerHTML={{ __html: textLeft }}></LeftIntro>
                </TweenText>
            </TimelineText>
        </Col>
        <Col sm={1} md={6} className="order-1 order-md-2">
                <TimelineText scrollTrigger={{ start: "top bottom", toggleActions: "restart none restart none"}}>
                    <TweenText 
                        splitText="lines"
                        from={{
                            duration: 1.5,
                            opacity: 0,
                            x: "random(-400, 400)",
                            stagger: 0.02
                        }}
                    >
                        <RightIntro id='right-intro'>
                            {textRight}
                        </RightIntro> 
                    </TweenText>
                </TimelineText>
        </Col>
    </RowResponsive>
));

export default Intro
