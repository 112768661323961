import { createStore, combineReducers } from 'redux';
import error from './errorReducer';

const store = createStore(
  combineReducers({
    error,
  })
);

export default store;
