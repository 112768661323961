import React from 'react'
import styled from 'styled-components'
import { motion } from "framer-motion";
import Timeline from '../animation/Timeline'
import Tween from '../animation/Tween'

const Container = styled(motion.div)`
    position: relative;
    transition: opacity 2s ease-out;
    height: 100%;

    &:hover {
        p { opacity: 0.5; }
    }
`

const Text = styled(motion.p)`
    font-size: 60px;
    line-height: 70px;

    div {
      font-family: 'Playfair Display', sans-serif !important;
      font-style: italic;
      font-weight: 700;
    }

    @media (max-width: 768px) {
        font-size: 45px;
        line-height: 74px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    @media (max-width: 375px) {
      font-size: 30px;
    }
`

const Img = styled(motion.div)`
    background: ${props => props.cover ? 'url("https://hub.agencelafusee.com/uploads/plume/novel/cover/' + props.cover + '");' : 
    props.illustration ? 'url("https://hub.agencelafusee.com/uploads/plume/book/illustration/' + props.illustration + '");'  : "none" }
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
`


// Animations
const ImgMotion = {
  hidden: {
    display: "none",
    x: 40,
    transition: {
      duration: 2,
      type: "spring",
      stiffness: 160,
      ease: "easeIn"
    }
  },
  hover: {
    display: "block",
    x: -20,
    transition: {
      duration: 0.4,
      type: "spring",
      stiffness: 160,
      ease: "easeOut"
    }
  }
};


const Extract = ({ text, cover, illustration }) => {

  return (
      <Container initial="hidden" whileHover="hover">
        <Timeline scrollTrigger={{ start: "top center", }}>
          <Tween 
            splitText="lines" 
            from={{ 
              duration: 1,
              opacity: 0,
              rotationX: -120,
              transformOrigin:"top center -150",
              stagger: 0.1,
            }} 
          >
            <Text className="extract-text">
              {text}
            </Text>
          </Tween>
        </Timeline>
        <Img variants={ImgMotion} cover={cover} illustration={illustration} />
      </Container>
  )
}

export default Extract
