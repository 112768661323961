import React from 'react'
import { Row, Container } from 'react-bootstrap';

const LegalNoticeContent = () => {
    return (
        <div>
            <Container>
                <h2 style={{fontSize: 40, fontWeight: 'bold', textTransform: 'uppercase'}}>Mentions Légales</h2>

                <h3 className="mt-5">IDENTIFICATION</h3>
                <p>Les informations suivantes sont fournies en vertu de l’Article 6 de la loi
                n° 2004-575 du 21 juin 2004 pour la Confiance dans l’Économie
                Numérique.</p>

                <h3 className="mt-5">LE SITE EST ÉDITÉ PAR :</h3>
                <p>Raison Social : Agence La Fusée<br/>
                Forme Juridique : SARL<br/>
                Siège social : AGENCE LA FUSÉE – 68 rue d’Hauteville, 75010 PARIS<br/>
                Capital social : 208.335 €<br/>
                RCS : RCS Paris B<br/>
                Code APE : 7022Z<br/>
                TVA intracommunautaire : FR 68505008144<br/>
                Numéro SIRET : 505 008 144 000 30<br/>
                Téléphone : 01 86 95 13 01<br/>
                Adresse électronique : a.murcia@agencelafusee.com<br/>
                </p>

                <h3 className="mt-5">HÉBERGEUR</h3>
                <p>PLANET-WORK<br/>
                Siège social : 231 rue Saint-Honoré, 75001 Paris - France<br/>
                Téléphone : 01 61 37 35 90<br/>
                </p>

                <h3 className="mt-5">CONCEPTION DU SITE</h3>
                <p>Agence La Fusée</p>

                <h3 className="mt-5">CONTENU DU SITE</h3>
                <p>L’Agence La Fusée s’efforce d’assurer l’exactitude et la mise à jour des
                informations diffusées sur ce site, et se réserve le droit de corriger, à tout
                moment et sans préavis, le contenu. L’Agence La Fusée décline toute
                responsabilité en cas de difficulté ou d’impossibilité d’accéder au site due à
                un problème de connexion internet. De même, l’Agence La Fusée ne

                pourra être tenue pour responsable d’aucun éléments en dehors de son
                contrôle et des dommages qui pourraient éventuellement être causés par
                l’environnement technique des utilisateurs du présent site, notamment les
                ordinateurs, logiciels, équipements réseaux (modems, téléphones…) et tout
                matériel utilisé pour accéder aux informations.</p>

                <h3 className="mt-5">PROPRIÉTÉ</h3>
                <p>L’ensemble de ce site et de ces éléments relèvent de la législation
                française et internationale sur le droit d’auteur, le droit des marques et des
                bases de données. De façon plus générale, sur la propriété intellectuelle
                aussi bien sur la forme (choix, couleurs, disposition etc.) que sur son
                contenu (textes, images, vidéos etc.). Toutes reproduction, représentation,
                diffusion ou rediffusion, en tout ou partie, du contenu de ce site sur quelque
                support ou par tout autre procédé, ainsi que toute vente, revente,
                retransmission ou mise à disposition de tiers de quelque manière que ce
                soit sont interdites sauf autorisation expresse et préalable du directeur de la
                publication.
                Le non-respect de cette interdiction constitue une contrefaçon susceptible
                d’engager la responsabilité civile et pénale du contrefacteur.</p>

                <h3 className="mt-5">TÉMOINS DE CONNEXION OU “COOKIES”</h3>
                <p>Afin de faciliter l’utilisation de notre site internet, des cookies pourront être
                utilisés. Il s’agit de fichiers texte stockés dans la mémoire de votre
                navigateur et qui servent à vous identifier à chaque changement de page
                sur le site. L’utilisation de ces cookies vous assure une navigation efficace
                et sécurisée.
                Ils peuvent être désactivés via les options de votre navigateur. Il vous suffit
                de modifier les réglages dans l’onglet “confidentialité”.
                Si vous voulez plus d’informations sur les cookies, vous pouvez consulter le
                site de la CNIL : https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</p>
            </Container>
        </div>
    )
}

export default LegalNoticeContent
