import React from 'react';
import 'core-js/stable'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { configure } from 'axios-hooks'
import axios from './api/axios';
import cache from './api/cache';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from 'gsap/SplitText';
import { SkeletonTheme } from "react-loading-skeleton";
import './index.css';

gsap.registerPlugin(SplitText);
gsap.registerPlugin(ScrollTrigger);
gsap.config({ nullTargetWarn: false, });

configure({ axios, cache });

ReactDOM.render(
  <React.StrictMode>
    <SkeletonTheme color="#ffffff3b" highlightColor="#003cff17"> 
      <App />
    </SkeletonTheme>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
