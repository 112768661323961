import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import Home from './pages/Home'
import NotFound from './pages/404';
import styled from 'styled-components';
import history from './history';
import RouteWrapper from './components/utils/RouteWrapper';
import { Provider as ReduxProvider } from 'react-redux';
import reduxStore from './redux/store';
import LegalNotice from './pages/LegalNotice';
import Rules from './pages/Rules'

const Body = styled.div`
    background: #003cff;
    
    * {
      cursor: none;
      font-family: 'Catamaran', sans-serif;
      color: #fff;
    }

    html, body {
      cursor: none;
      overflow-x: hidden;
    }

    section {
      min-height: 100vh;
      padding: 50px 0;
      display: flex;
      align-items: center;
    }

    .cursor {
      width: 30px;
      height: 30px;
      background-color: #fefefe;
      border-radius: 100%;
      position: fixed;
      transform: translate(-50%, -50%);
      pointer-events: none;
      z-index: 9999;
      transition: all 150ms ease;
      transition-property: opacity;
      
    }

    .cursor--link-hovered {
      border: 2px solid #fefefe!important;
      transform: translate(-50%, -50%) scale(1.25)!important;
      background-color: transparent!important;
    }

    .cursor--hidden {
      visibility: hidden
    }

    .grecaptcha-badge { 
      display: none!important;
    }
`

const App = () => {
  return (
    <ReduxProvider store={reduxStore}>
      <Body>
          <Router history={history}>
            <Switch>
                {/* HOME */}
                <RouteWrapper
                  exact
                  path="/" 
                  component={Home} 
                />

                {/* LEGAL NOTICE */}
                <RouteWrapper
                  exact
                  path="/mentions-legales" 
                  component={LegalNotice} 
                />

                 {/* RULES */}
                <RouteWrapper
                  exact
                  path="/reglement" 
                  component={Rules} 
                />

                {/* 404 */}
                <RouteWrapper
                  component={NotFound}
                />

              </Switch>
            </Router>
      </Body>
    </ReduxProvider>
  )
}

export default App

