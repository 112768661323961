import React, { useEffect } from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import Logo from '../../assets/img/Logo-ALF-blanc.svg'
import Ppda from '../../assets/img/LOGO-PPDA-2020.svg'
import { useLocation } from "react-router-dom";

const ImgAlf = styled.img`
    width: 120px;

    @media (max-width: 1024px) {
        width: 100px;
    }

    @media (max-width: 768px) {
        width: 120px;
    }
`

const Img = styled.img`
    width: 160px;

    @media (max-width: 768px) {
        width: 150px;
    }
`

const FooterContainer = styled.footer`
    background-color: #000;
    z-index: 9;
    position: relative;
`


const RowResponsive = styled(Row)`
    display: flex;
    flex-direction: row;

    .link {
        text-decoration: underline;

        &:hover {
            color: #fff;
        }
    }

    a {
        &:hover {
            color: #fff;
            text-decoration: none;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

const ColResponsive = styled(Col)`
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`

const Social = styled.a`
    // padding: 10px;

   .bi {
       font-size: 35px;
   }
`

const LinkPage = styled(Link)`
   color: grey!important;
   font-size: 20px;
   font-weight: 600;
`

const Adress = styled.p`
margin-top: 15px;
margin-left: 20px;
margin-bottom: 0;

@media (max-width: 768px) {
    margin-left: 0;
    text-align: center;
   }
`

const ColRight = styled.div`
   width: 35%; 

   @media (max-width: 768px) {
        width: 17%;
        text-align: center;
   }

   @media (max-width: 512px) {
        width: 30%;
        text-align: center;
   }
`

const Footer = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return (
        <FooterContainer>
            <Container>
                <RowResponsive className="pt-3 pb-3">
                    <ColResponsive className="d-flex align-items-center my-3 my-lg-0">
                        <Row className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
                            <a href="https://agencelafusee.com" target='_blank' rel='noreferrer noopener'>
                                <ImgAlf src={Logo} alt="Agence La Fusée" />
                            </a>
                            <Adress>
                                68 rue d'Hauteville <br />
                                75010 Paris<br /><br />
                                <a href="mailto:a.murcia@agencelafusee.com" target='_blank' rel='noreferrer noopener'>a.murcia@agencelafusee.com</a><br />
                                <a href="https://agencelafusee.com" target='_blank' rel='noreferrer noopener'>agencelafusee.com</a><br />
                            </Adress>
                        </Row>
                    </ColResponsive>

                    <Col className="d-flex justify-content-center align-items-end my-3 my-lg-0">
                        <p className="mb-0">
                            <LinkPage to="/mentions-legales">Mentions légales</LinkPage>
                        </p>
                    </Col>

                    <ColResponsive className="d-flex justify-content-end my-3 my-lg-0">
                        <ColRight>
                            <Row className="d-flex justify-content-center justify-content-lg-start">
                                <a className='link' href="https://actus.agencelafusee.com/" target='_blank' rel='noreferrer noopener'>L'actualité</a>
                            </Row>
                            <Row className="mt-1 d-flex justify-content-between">
                                {/* <Social href="https://fr-fr.facebook.com/agencelafusee/" target='_blank' rel='noreferrer noopener'><i class="bi bi-facebook"></i></Social> */}
                                {/* <Social href="https://www.instagram.com/agencelafusee/?hl=fr" target='_blank' rel='noreferrer noopener'><i class="bi bi-instagram"></i></Social> */}
                                <Social href="https://fr.linkedin.com/company/agence-la-fusee" target='_blank' rel='noreferrer noopener'><i className="bi bi-linkedin"></i></Social>
                            </Row>
                            <Row className="mt-3 d-flex justify-content-center justify-content-lg-start">
                                <a href="/">
                                    <Img src={Ppda} alt="Prix Plume d'Agence" />
                                </a>
                            </Row>
                        </ColRight>
                    </ColResponsive>
                </RowResponsive>
            </Container>
        </FooterContainer>
    )
}

export default Footer
