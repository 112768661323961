import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Intro from './Intro';
import TitleHead from './TitleHead';
import { Timeline, Tween, Reveal } from 'react-gsap';
import TimelineText from '../animation/Timeline'
import TweenText from '../animation/Tween'

const Title = React.forwardRef(({ title, textLeft, textRight, loading, ...other }, ref) => {
    return (
      <TimelineText scrollTrigger={{ start: "top center" }}>
        <TweenText from={{ opacity: 0 }} to={{ opacity: 1, duration: 2 }} > 
          <section
            ref={ref}
            className="py-md-5 flex-column section-title"
            {...other}
          >
              <Container>
                  <Row className="mb-2">
                      <Col>
                          <Timeline>
                            <Tween from={{ y: -400 }} to={{ y: 0 }} ease="elastic.out(2, 0.5)" stagger={0.2} duration={2}> 
                              <TitleHead>
                                {title}
                              </TitleHead>
                            </Tween>
                        </Timeline>
                      </Col>
                  </Row>
                  <Intro
                    loading={loading}
                    textLeft={textLeft}
                    textRight={textRight}
                  />
              </Container>
          </section>
        </TweenText>
      </TimelineText>
    )
});

export default Title
