import React from 'react';
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom';
import InternalError from '../../pages/500';

const RouteWrapper = ({ component, ...other }) => {
  const { error } = useSelector(state => state.error);

  return (
    <Route
      component={error?.status === 500 ? InternalError : component}
      {...other}
    />
  );
}

export default RouteWrapper;