export const actionTypes =  {
  add: 'ADD_ERROR',
}

const errorReducer = (
  state = {},
  action
) => {

  switch (action.type) {
    case actionTypes.add:
      return {
        ...state,
        error: action.payload,
      };
  }

  return state;
};

export default errorReducer;