import React from 'react'
import { Row, Col, Container } from 'react-bootstrap';
import Logo from '../../assets/img/LOGO-PPDA-2020.svg'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import useAxios from 'axios-hooks';
// import { Controller, Scene } from 'react-scrollmagic';
// import { Tween } from 'react-gsap';

const Img = styled.img`
    width: 250px;

    @media (max-width: 768px) {
        width: 150px;
        margin-top: 10px;
    }
`

const Button = styled.a`
    border: 1px solid #fff;
    background: transparent;
    margin: 0 10px 0 0;
    padding: 0 15px;
    cursor: pointer;
    position: relative;
    z-index: 100;
    &:hover {
        color: #003cff!important;
        background: #FFF;
        text-decoration: none;
        transform: all 1s ease;
    }

    @media (max-width: 375px) {
        margin-top: 5px;
    }
`

const ColButton = styled(Col)`
    @media (max-width: 375px) {
        display: flex;
        flex-direction: column;
    }
`

const ColResponsive = styled(Col)`
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
        justify-content: flex-start;
    }
`

const Header = () => {
    const [{ data: configData }] = useAxios('/ppda/config');

    const config = configData?.data

    return (
        <header>
            <Container>
                <Row className="py-4 mt-4 py-sm-1">
                    <ColButton sm={1} md={6}>
                        {config?.[1].value === "true" &&
                            <Button href="#section-form">Inscrivez-vous</Button>
                        }
                        <Button href="#section-laureate">La nouvelle lauréate</Button>
                    </ColButton>
                    <ColResponsive>
                    <Link to="/">
                        <Img src={Logo} alt="Prix Plume d'Agence"/>
                    </Link>
                    </ColResponsive>
                </Row>
            </Container>
        </header>
    )
}

export default Header
