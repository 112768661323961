import React from 'react'
import Logo from '../assets/img/LOGO-PPDA-2020.svg'
import Cursor from '../components/utils/Cursor/Cursor'
import styled from 'styled-components'
import LegalNoticeContent from '../components/Sections/LegalNoticeContent';
import Footer from '../components/Sections/Footer';
import HeaderBis from '../components/Sections/HeaderBis';

const ContainerHome = styled.div`
    overflow-x: hidden;
`

const Img = styled.img`
    width: 250px;

    @media (max-width: 768px) {
        width: 150px;
    }
`

const LegalNotice = () => {
    return (
        <ContainerHome>
            <Cursor/>
            
            <HeaderBis/>

            <LegalNoticeContent/>

            <Footer/>

        </ContainerHome>
    )
}

export default LegalNotice
