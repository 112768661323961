import React from 'react'
import { Link } from 'react-router-dom';
import { Row, Container } from 'react-bootstrap';
import styled from 'styled-components'
import Logo from '../assets/img/LOGO-PPDA-2020.svg'
import Cursor from '../components/utils/Cursor/Cursor'

const Img = styled.img`
    width: 250px;

    @media (max-width: 768px) {
        width: 150px;
    }
`

const Title = styled.h2`
    text-align: center;
    font-size: 100px;
    font-family: 'Playfair Display', sans-serif !important;
    font-style: italic;

    @media (max-width: 768px) {
        font-size: 40px;
    }
`

const Home = styled(Link)`
    text-align: center;
    margin-top: 20px;

    &:hover { color: #fff; }
`

const Text404 = styled.p`
    position: absolute;
    font-family: 'Courrier New', monospace;
    left: 0;
    top: 0;
    margin: 20px;
`

const Section = styled.section`
    min-height: 90vh!important;
`

const NotFound = () => {
    return (
        <>
            <Cursor/>
            <Container>
                <header className="p-4">
                    <Row className='d-flex justify-content-end'>
                        <Link to='/'>
                            <Img src={Logo} alt="Prix Plume d'Agence"/>
                        </Link>
                    </Row>
                </header>
                <Section className="p-0">
                    <Row className="d-flex justify-content-center">
                        <Title>Oh Zut !!<br /> Cette plume n'existe pas.</Title>
                        <Home to="/">Retourner à l'accueil</Home>
                    </Row>
                    <Text404>Erreur 404</Text404>
                </Section>
            </Container>
        </>
    )
}

export default NotFound
