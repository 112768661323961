import React from 'react'
import useAxios from 'axios-hooks';
import { Row, Col, Container } from 'react-bootstrap';
import Extract from '../utils/Extract';
import styled from 'styled-components'
import Order from '../utils/buttons/Order';
import { motion } from 'framer-motion'
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';

const Title = styled(motion.h2)`
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
`

const Intro = styled.p`
    font-size: 18px;
    font-family: 'Catamaran', sans-serif !important;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 60px;

    .collection-list {
        padding-left: 0;
        
        li {
            list-style: none;
            
            p {
                font-size: 14px;
                line-height: 7px;
                font-style: italic;
                font-family: 'Playfair Display', sans-serif !important;
            }

            span {
                font-style: initial;
                font-family: 'Catamaran', sans-serif !important;
                line-height: 1;
            }
        }
    }


`

const Collections = React.forwardRef(({props, ...other}, ref) => {
    const [{ data: contentData }] = useAxios('/ppda/contents');
    const [{ data: booksData }] = useAxios('/ppda/books');

    const data = contentData?.data

    const books = booksData?.data[0]

    return (
        <Tween from={{ opacity: 0 }} to={{ opacity: 1 }}>
            <section ref={ref} className="section-collection" {...props}>
                {data &&
                    <Container>
                        <Row className="mb-4">
                            <Col>
                                <Controller>
                                    <Scene duration={150} offset={-250} duration={300}>
                                        <Tween from={{ opacity: 0, x: 400 }} to={{ opacity: 1, x: 0 }} duration={3}>
                                            <Title className="collection-title">Le recueil prix <br /> plume d'agence 2020</Title>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="collection-left" sm={1} md={5}>
                            {books && 
                                <Controller>
                                    <Scene duration={150} offset={-250} duration={300}>
                                        <Tween from={{ opacity: 0, y: 200 }} to={{ opacity: 1, y: 0 }} duration={3}>
                                                <Intro dangerouslySetInnerHTML={{ __html: books?.description }}></Intro>
                                        </Tween>
                                    </Scene>
                                </Controller>
                            }
                            </Col>
                            <Col sm={1} md={{size: 7, offset: 1}} className="mt-n4 collection-right">
                            {books && 
                                <Extract 
                                    text={books?.hook}
                                    illustration={books?.illustrationName}
                                />
                            }
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Order 
                                    text={data?.["command-text-1"]?.value}
                                    textButton="Commander" 
                                    align="left"
                                    link={data?.command?.value}
                                />
                            </Col>
                        </Row> */}
                    </Container>
                }
            </section>
        </Tween>
    )
});

export default Collections
