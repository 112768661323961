import React, { useEffect, useState } from 'react';
import Footer from '../components/Sections/Footer';
import Header from '../components/Sections/Header';
import SignUp from '../components/Signup';
import Title from '../components/Sections/Title';
import Laureate from '../components/Sections/Laureate';
import Collections from '../components/Sections/Collections';
import Slider from '../components/Sections/Slider';
import styled from 'styled-components'
import useAxios from 'axios-hooks';
import Skeleton from 'react-loading-skeleton';
import { Col, Row, Container } from 'react-bootstrap';
import Icons from '../components/utils/Icons';
import Cursor from "../components/utils/Cursor/Cursor";

const ContainerHome = styled.div`
    overflow-x: hidden;
`

const Home = () => {
    const [{ data, loading }] = useAxios('/ppda/contents');
    const [contentData, setContentData] = useState();
    
    useEffect(() => {
      setContentData(data?.data);
    }, [data]);

    return (
        <ContainerHome>
            <Header />
          
            {loading &&
              <Container>
                <Row className='my-5'>
                  <Col>
                    <Skeleton height={20} count={2} style={{margin: "15px 0"}}/>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center">
                  <Col>
                    <Skeleton height={20} count={5} style={{margin: "15px 0"}}/>
                  </Col>
                  <Col>
                    <Skeleton height={20} count={4} style={{margin: "15px 0"}} />
                  </Col>
                </Row>

                <Row className="my-5 d-flex align-items-center">
                  <Col>
                    <Skeleton height={20} count={4} style={{margin: "15px 0"}} />
                  </Col>
                  <Col>
                    <Skeleton height={20} count={8} style={{margin: "15px 0"}}/>
                  </Col>
                </Row>

                <Row className="my-5 d-flex align-items-center">
                  <Col>
                    <Skeleton height={20} count={8} style={{margin: "15px 0"}}/>
                  </Col>
                  <Col>
                    <Skeleton height={20} count={4} style={{margin: "15px 0"}} />
                  </Col>
                </Row>

                <Row className="my-5 d-flex align-items-center">
                  <Col>
                    <Skeleton height={190} count={1} style={{margin: "15px 0"}}/>
                  </Col>
                  <Col>
                    <Skeleton height={190} count={1} style={{margin: "15px 0"}} />
                  </Col>
                  <Col>
                    <Skeleton height={190} count={1} style={{margin: "15px 0"}} />
                  </Col>
                  <Col>
                    <Skeleton height={190} count={1} style={{margin: "15px 0"}} />
                  </Col>
                  <Col>
                    <Skeleton height={190} count={1} style={{margin: "15px 0"}} />
                  </Col>
                </Row>

                <Row className="my-5 d-flex align-items-center">
                  <Col>
                    <Skeleton height={20} count={1} style={{margin: "15px 0"}}/>
                  </Col>
                </Row>

                <Row className="my-5">
                  <Col>
                    <Skeleton height={20} count={1} style={{margin: "15px 0"}}/>
                  </Col>
                  <Col>
                    <Skeleton height={20} count={8} style={{margin: "15px 0"}} />
                  </Col>
                </Row>
              </Container>
            } 

            {contentData &&
              <>
                <Cursor/>
                <Icons />
                {/* Title */}
                <Title
                  loading={loading}
                  title={contentData.title?.value}
                  textLeft={contentData['intro-left']?.value}
                  textRight={contentData['intro-right']?.value}
                />

                {/* Laureate */}
                <Laureate />
                
                {/* Collection */}
                <Collections />

                {/* Order */}
                <Slider />

                {/* Signup */}
                <SignUp />
            {/* </Cursor> */}
              </>
            } 
            <Footer />
        </ContainerHome>
    )
}

export default Home
