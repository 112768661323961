import React, { useRef, useState, useEffect } from 'react'
import classNames from "classnames";

const Cursor = ({ children }) => {
  // const childrenRef = useRef([]);

    const [position, setPosition] = useState({x: 0, y: 0});
    const [linkHovered, setLinkHovered] = useState(false);
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        addEventListeners();
        handleLinkHoverEvents();
        return () => removeEventListeners();
    }, []);

    const addEventListeners = () => {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseenter", onMouseEnter);
      document.addEventListener("mouseleave", onMouseLeave);
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseenter", onMouseEnter);
      document.removeEventListener("mouseleave", onMouseLeave);
    };

    const onMouseLeave = () => {
          setHidden(true);
      };
    
    const onMouseEnter = () => {
          setHidden(false);
    };

    const onMouseMove = (e) => {
        setPosition({x: e.clientX, y: e.clientY});
    };

    const handleLinkHoverEvents = () => {
      // childrenRef.current.forEach((el, index) => {
          document.querySelectorAll("a").forEach(el => {
            el.addEventListener("mouseover", () => setLinkHovered(true));
            el.addEventListener("mouseout", () => setLinkHovered(false));
          });
          document.querySelectorAll("#section-form span").forEach(el => {
            el.addEventListener("mouseover", () => setLinkHovered(true));
            el.addEventListener("mouseout", () => setLinkHovered(false));
          });
          document.querySelectorAll("#form-submit").forEach(el => {
            el.addEventListener("mouseover", () => setLinkHovered(true));
            el.addEventListener("mouseout", () => setLinkHovered(false));
          });
    };

    const cursorClasses = classNames(
      'cursor',
      {
          // 'cursor--clicked': clicked,
          'cursor--hidden': hidden,
          'cursor--link-hovered': linkHovered
      }
    );
    
    return (
      <>
      <div
        className={cursorClasses}
        style={{
            left: `${position.x}px`,
            top: `${position.y}px`
        }}
      />
      </>
    )
}

export default Cursor
