import React from 'react'
import { Row, Container } from 'react-bootstrap';
import styled from 'styled-components'
import Logo from '../assets/img/LOGO-PPDA-2020.svg'
import Cursor from '../components/utils/Cursor/Cursor'

const Img = styled.img`
    width: 250px;

    @media (max-width: 768px) {
        width: 150px;
    }
`

const Title = styled.h2`
    text-align: center;
    font-size: 75px;
    font-family: 'Playfair Display', sans-serif !important;
    font-style: italic;

    @media (max-width: 768px) {
        font-size: 40px;
    }
`

const Home = styled.a`
    text-align: center;
    margin-top: 20px;

    &:hover { color: #fff; }
`

const Text500 = styled.p`
    position: absolute;
    font-family: 'Courrier New', monospace;
    left: 0;
    top: 0;
    margin: 20px;
`

const Section = styled.section`
    min-height: 90vh!important;
`

const InternalError = () => {
    return (
        <Container>
            <Cursor/>
            <header className="p-4">
                <Row className='d-flex justify-content-end'>
                    <a href='/'>
                        <Img src={Logo} alt="Prix Plume d'Agence"/>
                    </a>
                </Row>
            </header>
            <Section>
                <Row className="d-flex justify-content-center">
                    <Title>Oups !!<br /> Notre site a perdu toutes ses plumes ! Nous faisons notre possible pour les récupérer.</Title>
                    <Home href="/">Retourner à l'accueil</Home>
                </Row>
                <Text500>Erreur 500</Text500>
            </Section>
        </Container>
    )
}

export default InternalError;
