import React from 'react'
import { Row, Container } from 'react-bootstrap';
import useAxios from 'axios-hooks';
import styled from 'styled-components'

const Span = styled.span`
    font-weight: bold;
`

const Link = styled.a`
    color: #fff!important;
`

const RulesContent = () => {
    const [{ data: configData }] = useAxios('/ppda/config');
    const config = configData?.data

    return (
        <div>
            <Container>
            <h2 style={{fontSize: 40, fontWeight: 'bold', textTransform: 'uppercase'}}>
                Prix plume d'agence {config && config?.[0].value}
            </h2>
            <h2>CONDITIONS DE PARTICIPATION ET RÈGLEMENT</h2>
            <h3 className="mt-5">Conditions de participation</h3>

            <h4 className="mt-5">L'AUTEUR(E) :</h4>
            <ul>
                <li>Doit justifier d’une expérience d’au moins 2 ans en agence de communication, quelle que soit sa fonction.</li>
                <li>Ne peut présenter qu’une seule œuvre par édition du Prix Plume d’Agence.</li>
                <li>Ne peut participer à la compétition s’il/elle fait partie de l’équipe d’organisation du Prix ou de son entourage.</li>
            </ul>

            <h4 className="mt-5">L'OEUVRE : </h4>
            <ul>
                <li>Doit être une Nouvelle (récit de fiction court, en prose).</li>
                <li>Doit être originale et jamais publiée.</li>
                <li>Doit être écrite en langue française.</li>
                <li>Le manuscrit envoyé doit être totalement anonyme.</li>
                <li>Ne doit pas excéder 30 000 signes, espaces compris (à raison de 1500 signes par page), et doit être composée en police de caractère Arial, corps 12, interligne 1.5, avec une marque de 3cm en haut/bas/gauche/droite.</li>
                <li>Aura pour thème un sujet libre. Le jury se réserve le droit d’écarter de la compétition toute œuvre qui porterait atteinte aux bonnes mœurs, ferait acte de pornographie, racisme, diffamation, atteinte à la vie privée et au droit à l’image, etc...</li>
            </ul>

            <h4 className="mt-5">LA MARCHE À SUIVRE :</h4>
            <ul>
                <li>L’inscription se fait sur le formulaire suivant: <Link href="/#section-form" rel='noreferrer noopener'>je m’inscris</Link><br />Pour l’édition 2022-2023, les inscriptions seront closes le 30 septembre 2022 à minuit.</li>
                <li>A l’issue de l’inscription, un email de confirmation est envoyé avec votre code de participation (4 chiffres et 4 lettres).</li>
                <li>Faire figurer votre code de participation sur le premier feuillet de l’oeuvre.</li>
                <li>Adresser avant la date limite d’inscription une version électronique de l’œuvre à : </li>
                <Link href="mailto:prixplumedagence@agencelafusee.com" className="d-flex justify-content-center my-4 fw-bolder" style={{fontWeight: 'bold'}}>prixplumedagence@agencelafusee.com</Link>
                <li>Envoyer un exemplaire imprimé de votre manuscrit par voie postale à :</li>
                <p className="text-center mt-4" style={{fontWeight: 'bold'}}>Agence La Fusée - Prix Plume d’Agence <br></br> 68 rue d’hauteville <br></br> 75010 - Paris</p>
            </ul>


            <h3 className="mt-5">Le Règlement</h3>
            <p><Span>Art. 1 </Span>Le Prix Plume d’Agence, créé en 2009 par l’agence <span style={{fontStyle: 'italic',}}>il était une marque </span>, est destiné à faire connaître de nouveaux talents issus du monde de la communication et à couronner chaque année une Nouvelle sur manuscrit inédit et anonyme, œuvre d’un écrivain de langue française.</p>
            <p><Span>Art. 2 </Span>Pour l’attribution du Prix il est tenu compte par le Jury, entre autres considérations, des qualités littéraires et du respect du format imposé. En outre, l’auteur doit pouvoir justifier de deux ans minimum de travail en agence de communication (opérationnelle, digitale, publicitaire, médias...) à la date de la clôture du concours.</p>
            <p><Span>Art. 3 </Span>La remise officielle du Prix Plume d’Agence au lauréat se déroulera le jour de la proclamation des résultats, dans un lieu qui sera précisé ultérieurement. Le lauréat s’engage à être présent lors de la remise du Prix, sauf circonstances exceptionnelles et justifiées.</p>
            <p><Span>Art. 4 </Span>Les meilleurs auteurs verront leur texte publié dans un recueil.</p>
            <p><Span>Art. 5 </Span>Les auteurs publiés recevront un ou des exemplaires du recueil édité.</p>
            <p><Span>Art. 6 </Span>La sélection de l’œuvre primée est réalisée par deux Jurys successifs. Un premier Jury, appelé Comité de lecture et composé des collaborateurs et des partenaires de l’Agence la Fuséee, établit une sélection d’une vingtaine de manuscrits. Un deuxième Jury, composé de professionnels de la communication et de l’édition, désignera le lauréat, les prix spéciaux et les nouvelles éditées. Les décisions du jury sont sans appel.</p>
            <p><Span>Art. 7 </Span>Les textes remportant les 3 premiers prix pourront être lus en public lors de la proclamation des résultats.</p>
            <p><Span>Art. 8 </Span>Les manuscrits doivent être expédiés par la Poste avant le 30 septembre 2022 à minuit (le cachet de la Poste faisant foi), en deux exemplaires, à l’adresse suivante : Agence La Fusée - Prix Plume d’Agence - 68 rue Hauteville - 75010 PARIS. Une version électronique sous Word devra également être envoyée à : <Link href="mailto:prixplumedagence@agencelafusee.com">prixplumedagence@agencelafusee.com</Link>. Le nom de l’auteur ne doit paraître sur aucun feuillet du manuscrit.</p>
            <p><Span>Art. 9 </Span>Afin d’assurer l’anonymat des textes, un code composé de 4 chiffres et 4 lettres (exemple : 8679-FVAT) doit figurer en haut à droite du premier feuillet. Les textes ne devront porter ni titre, signature, ni signe distinctif, sous peine d’élimination du concours. Le candidat joindra à ses manuscrits une feuille de participation comportant son nom, son prénom, son code d’identification, le titre de son œuvre, ainsi que son adresse, son numéro de téléphone et son adresse mail.</p>
            <p><Span>Art. 10 </Span>Les manuscrits ne doivent pas excéder 30 000 signes, espaces compris (à raison de 1 500 signes par page), et doivent être composés en police de caractère Arial, corps 12, interligne 1.5, avec une marge de 3 cm en haut/bas/gauche/droite.</p>
            <p><Span>Art. 11 </Span>Les auteurs s’engagent par le dépôt de leur manuscrit à ne pas proposer leur œuvre à un autre éditeur avant la proclamation des résultats.</p>
            <p><Span>Art. 12 </Span>Il est possible de concourir chaque année, mais un seul texte peut être envoyé par participant. Un postulant ne peut proposer une deuxième fois un même manuscrit.</p>
            <p><Span>Art. 13 </Span>L’auteur s’engage à ce que son manuscrit ne contienne rien qui puisse tomber sous le coup de la loi et autres dispositions relatives à la diffamation et à l’injure, à la vie privée et au droit à l’image, à l’atteinte aux bonnes mœurs ou au plagiat.</p>
            <p><Span>Art. 14 </Span>La participation au concours vaut autorisation tacite de reproduction du texte par l’Agence La Fusée. Les auteurs autorisent que leur Nouvelle soit publiée sur tous les supports destinés à la promotion du concours et/ou de l’Agence La Fusée. Les lauréats s’engagent à ne pas demander de droits d’auteur pour ces publications.</p>
            <p><Span>Art. 15 </Span>Toute personne participant au fonctionnement ou à l’organisation du Prix Plume d’Agence ne peut faire acte de candidature. Cette mesure s’étend à son entourage familial.</p>
            <p><Span>Art. 16 </Span>Le dépôt d’un manuscrit à l’Agence La Fusée implique l’adhésion pleine et entière au présent règlement.</p>
            <p><Span>Art. 17 </Span>Pour tous renseignements complémentaires :</p>
            <ul>
                <li>Site Internet : <Link href="https://prix-plume.agencelafusee.com" target='_blank' rel='noreferrer noopener'>prix-plume.agencelafusee.com</Link></li>
                <li>Mail : <Link href="mailto:prixplumedagence@agencelafusee.com">prixplumedagence@agencelafusee.com</Link></li>
                <li>Contact : Alain - <Link href="tel:+33 (0)1 86 95 13 01">+33 (0)1 86 95 13 21</Link></li>   
            </ul>
            
            </Container>
        </div>
    )
}

export default RulesContent
