import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { FormGroup } from 'react-bootstrap';
import { signUpSchema } from './signUpSchema';
import FormControl from '../utils/FormControl/FormControl';
import ReCAPTCHA from 'react-google-recaptcha';
import useAxios from 'axios-hooks';
import { object } from 'yup';
import { Row, Col, Container } from 'react-bootstrap';
import styled from 'styled-components'
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const Resum = styled.p`
    font-size: 22px;
    font-family: 'Catamaran', sans-serif !important;
    font-weight: 500;
    line-height: 39px;
    padding-right: 8px;
    display: inline;
`;

const TitleHead = styled.h2`
    font-size: 243px;
    font-family: 'Playfair Display', sans-serif !important;
    font-style: italic;
    font-weight: 700;
    margin-top: -103px;
    text-indent: -1em;
    padding-left: 1.1em;

    @media (max-width: 1024px) {
      font-size: 195px;
    }

    @media (max-width: 768px) {
      margin-top: 0;
      font-size: 145px;
    }

    @media (max-width: 512px) {
      margin-top: 0;
      font-size: 85px;
      text-indent: inherit;
      padding-left: 0;
    }
`;

const Submit = styled.button`
  background: #fff;
  color: #003cff!important;
  border: 1px solid #fff;
  text-align: center;
  padding: 10px 50px;
  font-size: 20px;
  text-align: center;

  &:hover {
      text-decoration: none;
      background: none;
      color: #fff!important;
      cursor: none;
  }
`

const SpanStyled = styled.span`
  font-size: 20px;
`;

const FormStyled = styled(Form)`
  margin: 20px 45px 20px 0px;
`;

const FormikChecked = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 35px;
  padding-right: 80px;

  i { margin-right: 10px;}

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const LinkPage = styled(Link)`
   color: #fff!important;
   text-decoration: underline;
`

const SignUp = React.forwardRef((props, ref) => {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  const [{ data }, addUser] = useAxios(
    {
      url: '/ppda/register',
      method: 'POST',
    },
    { manual: true },
  );
  const recaptchaRef = React.useRef();
  
  const [{ data: configData }] = useAxios('/ppda/config');

  const config = configData?.data

  const handleSubmit = async (data, { resetForm }) => {
    addUser({
      data: {
        ...data,
        recaptchaToken: await recaptchaRef.current.executeAsync(),
        edition: config[0].value
      },
    });
    resetForm();
  }

  return (
    <>
    {config?.[1].value === "true" &&
      <section ref={ref} id="section-form" {...props}>
        <Container>
            <Row>
              <Controller>
                <Scene duration={300} offset={-250}>
                  <Timeline>
                    <Tween from={{ opacity: 0, x: 400 }} to={{ opacity: 1, x: 0 }}>
                      <TitleHead className="form-title-1">Inscrivez-</TitleHead>
                    </Tween>
                  </Timeline>
                </Scene>
              </Controller>
            </Row>
            <Row>
              <Col sm={1} md={6}>
                <Controller>
                  <Scene duration={300} offset={-200}>
                    <Timeline>
                      <Tween from={{ opacity: 0, y: 200 }} to={{ opacity: 1, y: 0 }}>
                        <TitleHead className="form-title-2">vous</TitleHead>
                      </Tween>
                    </Timeline>
                  </Scene>
                </Controller>
              </Col>
              <Col sm={1} md={6}>
                <SpanStyled>Avant de vous inscrire, merci de lire notre <LinkPage to='/reglement'>règlement</LinkPage>.</SpanStyled>
                {data ?
                  <FormikChecked>
                    <i className="bi bi-check-circle"></i>
                    <p>{data.message}</p>
                  </FormikChecked>
                :
                <Formik
                  initialValues={{
                    email: '',
                    firstname: '',
                    lastname: '',
                    company: '',
                    phone: '',
                  }}
                  validationSchema={object().shape(signUpSchema)}
                  onSubmit={handleSubmit}
                >
                  {({ errors, isSubmitting }) => (
                  <FormStyled>
                    <FormGroup controlId="form-signup">
                      <Resum>Bonjour, je m’inscris à l’édition 2022 - 2023 du Prix Plume d’Agence avant le 30 septembre 2022.<br /><br /></Resum>
                      <Resum>Je pense avoir du talent pour l’écriture. En tout cas, ma mère adore me lire. Je m’appelle</Resum>
                      <FormControl
                        style={{ width: 82 }}
                        name="firstname"
                        type="text"
                        placeholder="Prénom"
                      />
                      <FormControl
                        style={{ width: 53 }}
                        name="lastname"
                        type="text"
                        placeholder="Nom"
                      />
                      <Resum>. J’ai travaillé au moins deux années dans les agences de communication suivantes</Resum>
                      <FormControl
                        style={{ width: 80 }}
                        name="company"
                        type="text"
                        placeholder="Agence"
                      />
                      <Resum>Vous pouvez m’appeler au</Resum>
                      <FormControl
                        style={{ width: 195 }}
                        name="phone"
                        type="tel"
                        placeholder="mon téléphone"
                      />
                      <Resum>et m’écrire à</Resum>
                      <FormControl
                        style={{ width: 195 }}
                        name="email"
                        type="email"
                        placeholder="mon adresse@mail"
                      />
                      <Resum>car moi aussi j’adore recevoir des messages bien écrits. <i className="bi bi-emoji-smile"></i><br /></Resum>
                      <Resum><br />J’ai bien noté que je dois envoyer mon manuscrit avant le 30 septembre 2022 par e-mail et courrier.</Resum>
                    </FormGroup>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6LdtPcMUAAAAAJ6fDlEtHmfZBim_8FvIG3qsthah"
                    />
                    {errors.firstName ? (
                      <div>{errors.firstName}</div>
                    ) : null}
                    <div className="d-flex justify-content-start justify-content-md-end">
                      <Submit
                        id="form-submit"
                        type="submit"
                        disabled={isSubmitting}
                      >
                          Valider
                        </Submit>
                    </div>
                  </FormStyled>
                  )}
                </Formik>
                }
              </Col>
            </Row>
          </Container>
        </section>}
    </>
  );
});

export default SignUp;