import React from 'react'
import useAxios from 'axios-hooks';
import styled, { keyframes } from 'styled-components'
import { motion } from 'framer-motion'
import Order from '../utils/buttons/Order'
import { Row, Col, Container } from 'react-bootstrap'

const Title = styled.h2`
    text-align:center;
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
`

const Scroll = keyframes`
    100% { left: -2240px; }
`
    
const ContainerSlider = styled.div`
    position: relative;

    overflow-x: hidden;
    overflow-y: hidden;
    width: 2425px;
    height: 485px;
    
`

const ContentSlider = styled.div`
    display: flex;
    position: absolute;
    left: 0;

    animation: ${Scroll} linear 23s infinite;

    &:hover {
        animation-play-state: paused;
    }
`

const BookContainer = styled.div`
    text-align: center;
    width: 280px;
    padding: 50px;
    transition: all .2s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`
    
const Edition = styled.p`
    font-family: 'Catamaran'!important;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
`

const SubTitle = styled.p`
    font-family: 'Playfair Display', sans-serif !important;
    font-weight: 400;
    font-size: 18px;
`

const CoverImg = styled.img`
    width: 180px;
`

const Slider = React.forwardRef(({props, ...other}, ref) => {
    const [{ data: ContentsData }] = useAxios('/ppda/contents');
    const [{ data: booksData }] = useAxios('/ppda/books');
    const data = ContentsData?.data
    const books = booksData?.data

    return (
        <section ref={ref}>
            {books &&
                <Container fluid>
                    <Row className="mb-5">
                        <Col>
                            <Title>Les prix plume d'agence <br /> depuis 2009</Title>
                        </Col>
                    </Row>

                    <ContainerSlider>
                        <ContentSlider>
                            {books && 
                                books.map((book) => 
                                    <BookContainer 
                                        key={book?.id}
                                    >
                                        <Edition>Édition {book?.edition}</Edition>
                                        <SubTitle>{book?.title}</SubTitle>
                                        <CoverImg src={'https://hub.agencelafusee.com/uploads/plume/book/cover/' + book?.coverName} alt={book?.title} />
                                    </BookContainer>
                            )}
                            {books && 
                                books.map((book, index) => 
                                    index <= 5 && (
                                        <BookContainer 
                                            key={book?.id}
                                        >
                                            <Edition>Édition {book?.edition}</Edition>
                                            <SubTitle>{book?.title}</SubTitle>
                                            <CoverImg src={'https://hub.agencelafusee.com/uploads/plume/book/cover/' + book?.coverName} alt={book?.title} />
                                        </BookContainer>
                                    )
                                )
                            }
                        </ContentSlider>
                    </ContainerSlider>
                    {/* <Order 
                        text={data?.['command-text-2']?.value}
                        textButton="Commander"
                        align="center"
                        link={data?.command?.value}
                    /> */}
                </Container>
            }
        </section>
    )
});

export default Slider
