import React, { useEffect, useRef, useState } from 'react';
import GsapContext from '../context/GsapContext';
import gsapCore from 'gsap/gsap-core';

export const Timeline = (
  {
    children,
    scrollTrigger,
    ...other
  }
) => {
  const [gsap, setGsap] = useState(null);
  const childRef = useRef();

  useEffect(() => {
    if (scrollTrigger) {
      scrollTrigger.trigger = childRef.current;
    }

    setGsap(gsapCore.timeline({
      scrollTrigger,
      ...other,
    }));
  }, []);

  return (
    <GsapContext.Provider value={gsap}>
      <div ref={childRef}>
        {React.Children.only(
          React.cloneElement(children
        ))}
      </div>
    </GsapContext.Provider>
  );
}

export default Timeline;