import { number, string } from "yup";

const isRequired = (field) => {
  return `${field} est requis`;
}

export const signUpSchema = {
  email: string().required(isRequired('L\'email')).email('L\'email n\'est pas correct'),
  firstname: string().required(isRequired('Le prénom')),
  lastname: string().required(isRequired('Le nom')),
  company: string().required(isRequired('L\'agence')),
  phone: number().typeError('Veuillez renseigner un numéro valide').min(10, "Veuillez renseigner au moins 10 chiffres").required(isRequired('Le numéro')),
};