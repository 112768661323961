import Axios from 'axios'
import store from '../redux/store';

const axios = Axios.create({
  baseURL: 'https://hub.agencelafusee.com/api',
});

axios.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, (error) => {
  const status = error?.response?.status;

  switch (status) {
    case 404: {
      return error;
    }
    default:
      store.dispatch({
        type: 'ADD_ERROR',
        payload: {
          status: 500,
        }
      })
  }

  return error;
});

export default axios;